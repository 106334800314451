import debounce from "lodash/debounce"
import isEmpty from "lodash/isEmpty"
import sum from "lodash/sum"

import Http from "@/lib/Http"
import { TagCategories } from "@/lib/ListingTagManager"
import { ImpressionListings } from "@/lib/Blytics"
import { replaceState as FilterReplaceState } from "@/lib/Listing/FilterToPath"
import { basePath } from "@/lib/Listing/PathHelpers"
import { setRobotsMetaTag, setLinkHeadTag } from "@/lib/PageMetaManager"
import { categories, defaultSortOption } from "@/lib/ListingFilters"

const timeToFetchListings = 1500 //ms

const debouncedFetchListings = debounce((dispatch) => {
  dispatch("fetchListings")
}, timeToFetchListings)

const DEFAULT_FILTERS = (moduleInit = false) => {
  return {
    per: undefined,
    page: undefined,
    category: "All Art",
    collections: [],
    competitions: [],
    competition_category_ids: [],
    mediums: [],
    art_styles: [],
    colors: [],
    sizes: [],
    locations: [],
    orientations: [],
    artwork_statuses: [],
    prices: [],
    price_range: [],
    height: [],
    width: [],
    term: "",
    sort: moduleInit ? "relevant" : defaultSortOption(),
    ready_to_hang: "all",
    from_followeds: "all",
    availability: "for_sale_web",
    featured: false,
    artist_discount_filter_preference: false,
    framing_filter_preference: false,
  }
}

const filterSanitizers = {
  colors: (colors) => colors.slice(0, colorFilterLimit),
}

export const colorFilterLimit = 2
const limitMinValue = 0
const limitMaxValue = 300

const limitMin = (value) => {
  return value || limitMinValue
}

const limitMax = (value) => {
  return value || limitMaxValue
}

const DEFAULT_DIMENSION = () => {
  return {
    width: {
      min: null,
      max: null,
    },
    height: {
      min: null,
      max: null,
    },
  }
}

const FilterModule = {
  namespaced: true,
  state: {
    // Used to reset since masonry manipulates DOM and does not teardown
    viewKey: Date.now(),
    loading: true,
    listings_count: 0,
    partial_count: 0,
    scrollPosition: 0,
    view: "grid",
    listings: [],
    basePath: "",
    filters: {
      ...DEFAULT_FILTERS(true),
      ...DEFAULT_DIMENSION(),
    },
    fixed_filters: {},
    autoSuggest: null,
    config: {},
    dimension: DEFAULT_DIMENSION(),
    userArtist: false,
  },
  getters: {
    filtersCount: (state) => {
      const {
        collections,
        competitions,
        art_styles,
        colors,
        locations,
        mediums,
        orientations,
        artwork_statuses,
        prices,
        price_range,
        sizes,
        height,
        artist_discount_filter_preference,
      } = state.filters

      return sum([
        isEmpty(collections) ? 0 : 1,
        isEmpty(competitions) ? 0 : 1,
        isEmpty(art_styles) ? 0 : 1,
        isEmpty(mediums) ? 0 : 1,
        isEmpty(colors) ? 0 : 1,
        isEmpty(locations) ? 0 : 1,
        isEmpty(orientations) ? 0 : 1,
        isEmpty(artwork_statuses) ? 0 : 1,
        isEmpty(prices) ? 0 : 1,
        isEmpty(sizes) ? 0 : 1,
        isEmpty(price_range) ? 0 : 1,
        isEmpty(height) ? 0 : 1,
        artist_discount_filter_preference ? 1 : 0,
      ])
    },
    fromFollows: (state) => state.filters.from_followeds == "from_followeds",
    readyToHang: (state) => state.filters.ready_to_hang == "ready_to_hang",
    dimensionsFilled: ({ dimension }) => {
      const { width, height } = dimension
      const { max: wmax, min: wmin } = width
      const { max: hmax, min: hmin } = height

      const collect = [wmax, wmin, hmax, hmin]
      return collect.some((v) => v != null)
    },
  },
  mutations: {
    setView(state, view) {
      state.view = view
    },
    setListingsCount(state, count) {
      state.listings_count = count
    },
    setListingsPartialCount(state, count) {
      state.partial_count = count
    },
    setListings(state, listings) {
      state.viewKey = Date.now()
      state.listings = listings
    },
    setListing(state, { id, listing }) {
      let replace = state.listings.find((listing) => listing.id == id)

      state.listings.splice(state.listings.indexOf(replace), 1, listing)
    },
    setFilter(state, { filterType, filter }) {
      state.filters[filterType] = filter
    },
    setFixedFilters(state, filterTypes) {
      filterTypes.forEach((filterType) => (state.fixed_filters[filterType] = state.filters[filterType]))
    },
    mergeFilter(state, filter) {
      state.filters = Object.assign(state.filters, filter)
      this.commit("filter/sanitizeFilters", Object.keys(filter))
    },
    sanitizeFilters(state, filterKeys) {
      filterKeys.forEach((filterKey) => {
        const sanitizer = filterSanitizers[filterKey]
        if (sanitizer) state.filters[filterKey] = sanitizer(state.filters[filterKey])
      })
    },
    setConfig(state, config) {
      state.config = config
    },
    resetFilters(state) {
      state.filters = { ...DEFAULT_FILTERS(), ...state.fixed_filters, ...state.config.filter_preferences }
    },
    resetFixedFilters(state) {
      state.fixed_filters = []
    },
    clearFilter(state, filterType) {
      state.filters[filterType] = DEFAULT_FILTERS()[filterType]
    },
    deleteFilters(state, keys) {
      keys.forEach((key) => {
        delete state.filters[key]
      })
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    paginate(state, page) {
      state.filters.page = page
    },
    setPerPage(state, per) {
      state.filters.per = per
    },
    setBasePath(state) {
      state.filters.basePath = basePath()
    },
    setDimension(state, dimension) {
      state.dimension = dimension
    },
    setUserArtist(state, userArtist) {
      state.userArtist = userArtist
    },
    extractDimensions(state) {
      const [wmin, wmax] = state.filters.width
      const [hmin, hmax] = state.filters.height

      state.dimension = {
        width: {
          min: wmin,
          max: wmax,
        },
        height: {
          min: hmin,
          max: hmax,
        },
      }
    },
    setAutoSuggest(state, suggest) {
      state.autoSuggest = suggest
    },
  },
  actions: {
    initListings({ commit, state, dispatch }, filters) {
      if (state.basePath !== basePath()) commit("resetFixedFilters")
      commit("resetFilters")
      commit("setBasePath")
      commit("mergeFilter", filters)
      commit("extractDimensions")
      commit("setFixedFilters", ["competitions", "featured", "exclusive", "case_study"]) // /competitions/1/entries, /staff-picks, /featured-art
      dispatch("fetchListings")
    },
    fetchListings({ commit, state, dispatch }) {
      commit("setLoading", true)
      commit("setListings", [])
      if (window.location.pathname !== "/") {
        $("html,body").animate({ scrollTop: 0 }, 200)
      }

      Http.get("/api/listings", { params: state.filters }).then((response) => {
        const { data } = response
        commit("setListingsCount", data.listings_count)
        commit("setListingsPartialCount", data.partial_count)
        commit("setLoading", false)
        commit("setListings", data.listings)
        commit("setPerPage", data.per)
        commit("paginate", data.page)
        commit("setAutoSuggest", data.auto_suggest)
        TagCategories(response.data.listings, state.filters.term)
        ImpressionListings(response.data.listings)
        setRobotsMetaTag(response.data.meta_tags?.robots)

        let baseUrl = new URL(window.location.href)
        baseUrl.searchParams.set("page", data.page + 1)
        if (data.page != data.total_pages) setLinkHeadTag("next", baseUrl.href)
        baseUrl.searchParams.set("page", data.page - 1)
        if (data.page > 1) setLinkHeadTag("prev", baseUrl.href)
      })
    },
    setConfig({ commit }, config) {
      commit("setConfig", config)
    },
    mergeFilter({ commit, dispatch, state }, filter) {
      commit("mergeFilter", { ...filter, page: undefined })
      commit("sanitizeFilters", Object.keys(filter))
      FilterReplaceState(state.filters)

      debouncedFetchListings(dispatch)
    },
    selectCategory({ dispatch }, category) {
      dispatch("mergeFilter", { category: category, mediums: categories[category], page: undefined })
    },
    setFilter({ commit, dispatch, state }, { filterType, filter }) {
      commit("setFilter", { filterType, filter })
      commit("paginate", undefined)
      FilterReplaceState(state.filters)
      debouncedFetchListings(dispatch)
    },
    setFixedFilters({ commit }, filterTypes) {
      commit("setFixedFilters", filterTypes)
    },
    deleteFilters({ commit, dispatch }, keys) {
      commit("deleteFilters", keys)
      commit("paginate", undefined)
      debouncedFetchListings(dispatch)
    },
    removeListingFromCart({ commit, state }, id) {
      const listing = state.listings.find((listing) => listing.id == id)
      commit("setListing", {
        id: id,
        listing: Object.assign(listing, { in_cart: false }),
      })
    },
    resetFilters({ commit, dispatch, state }, filters = state.fixed_filters) {
      commit("setDimension", DEFAULT_DIMENSION())
      commit("resetFilters")

      if (filters && Object.keys(filters).length > 0) {
        commit("mergeFilter", filters)
        FilterReplaceState(state.filters)
      } else {
        history.replaceState({ turbo: {} }, "", basePath())
      }

      dispatch("fetchListings")
    },
    hardResetFilters({ commit, dispatch }) {
      commit("resetFixedFilters")
      dispatch("resetFilters")
    },
    clearFilter({ commit, dispatch, state }, filterType) {
      if (filterType instanceof Array) {
        filterType.forEach((filter) => commit("clearFilter", filter))
      } else {
        commit("clearFilter", filterType)
      }
      commit("paginate", undefined)
      dispatch("fetchListings")
      FilterReplaceState(state.filters)
    },
    setListing({ commit }, { id, listing }) {
      commit("setListing", { id, listing })
    },
    changePage({ dispatch, commit }, { page }) {
      commit("setListings", [])
      commit("paginate", page)
      dispatch("fetchListings")
    },
    setView({ commit, dispatch, state }, view) {
      commit("setView", view)
      debouncedFetchListings(dispatch)
    },
    setPerPage({ commit, dispatch, state }, per) {
      commit("setPerPage", per)
      commit("paginate", undefined)
      commit("setListings", [])
      dispatch("fetchListings")
      FilterReplaceState(state.filters)
    },

    setDimension({ commit }, dimension) {
      commit("setDimension", dimension)
    },
    mergeDimensions({ state, dispatch, getters }) {
      if (!getters.dimensionsFilled) {
        dispatch("mergeFilter", { width: [], height: [] })
        return
      }

      const { width: w, height: h } = state.dimension

      dispatch("mergeFilter", {
        width: [limitMin(w.min), limitMax(w.max)],
        height: [limitMin(h.min), limitMax(h.max)],
        sizes: [],
      })
    },
    resetDimension({ commit }) {
      commit("setDimension", DEFAULT_DIMENSION())
    },
    setUserArtist({ commit }, userArtist) {
      commit("setUserArtist", userArtist)
    },
  },
}

export default FilterModule
