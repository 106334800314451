export const TagCategories = (listings, query) => {
  criteoListings({
    listings: listings.slice(0, 3).map((listing) => listing.id),
    event: "crto_listingpage",
  })

  tagListings(listings, query)
}

export const TagQuickview = (listing) => {
  dataLayer.push({
    event: "productClick",
    ecommerce: {
      click: {
        products: [
          {
            name: listing.title,
            id: listing.id,
            price: listing.price.dollars,
            brand: listing.artist.full_name,
            category: listing.medium.title,
          },
        ],
      },
    },
  })
}

function criteoListings(data) {
  dataLayer.push({
    event: data.event,
    crto: {
      email: window.config.userEmail,
      products: data.listings,
    },
  })
}

function tagListings(listings, query) {
  // UGA
  const listingsData = listings.map((listing, index) => {
    return {
      name: listing.title,
      id: listing.id,
      price: listing.price.dollars,
      brand: listing.artist.full_name,
      list: !!query ? "Search Results" : "Category",
      position: index,
    }
  })
  dataLayer.push({
    event: "Product Impression",
    ecommerce: {
      impressions: listingsData,
    },
    query: query,
  })
  // GA4
  const relatedListingsData = listings.map((listing, index) => {
    return {
      item_name: listing.title,
      itm_id: listing.id,
      item_brand: listing.artist.full_name,
      price: parseInt(listing.price.dollars),
      index: index,
      quantity: 1,
    }
  })
  dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      item_list_id: "related_products",
      item_list_name: "Related products",
      items: relatedListingsData,
    },
  })
}
export default { TagCategories, TagQuickview }
