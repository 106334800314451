export const setRobotsMetaTag = (robots_tag) => {
  if (robots_tag == null) {
    return
  }

  const robotMetaTag = document.head.querySelector("[name=robots]")
  robotMetaTag.content = robots_tag
}

export const setLinkHeadTag = (rel, url) => {
  let new_tag = document.createElement("a")
  new_tag.href = url
  new_tag.rel = rel

  let target = document.head.querySelector(`[rel=${rel}]`)
  target ? target.replaceWith(new_tag) : document.head.appendChild(new_tag)
}
