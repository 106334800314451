import isEmpty from "lodash/isEmpty"
import { basePath } from "@/lib/Listing/PathHelpers"

export default function filterToPath(filters) {
  const generatedPath = [
    basePath(),
    searchTermPath(filters.term),
    joinCollectionPath("prize-categories", filters.competition_category_ids),
    joinCollectionPath("finalists", filters.competition_finalist_hashids),
    joinCollectionPath("collection", filters.collections),
    joinCollectionPath("medium", filters.mediums),
    joinCollectionPath("location", filters.locations),
    joinCollectionPath("size", filters.sizes),
    joinCollectionPath("orientation", filters.orientations),
    joinCollectionPath("artwork_statuses", filters.artwork_statuses),
    joinCollectionPath("style", filters.art_styles),
    joinCollectionPath("price", filters.prices),
    joinCollectionPath("colour", filters.colors),
    filters.case_study ? `/case-study/${filters.case_study}` : "",
    booleanPath("sold", filters.availability, "all"),
    booleanPath("hang", filters.ready_to_hang, "ready_to_hang"),
    booleanPath("follow", filters.from_followeds, "from_followeds"),
    rangePath("width", filters.width),
    rangePath("height", filters.height),
    rangePath("price-range", filters.price_range),
    perPagePath(filters.per),
    sortPath(filters.sort),
  ].join("")

  return generatedPath
}

export function replaceState(filters) {
  history.replaceState({ turbo: {} }, "", filterToPath(filters))
}

function searchTermPath(term) {
  if (term == null || term == "") return null

  return `/search/${term.replace(" ", "-")}`
}

function perPagePath(pages) {
  if (pages == null || pages == "") return null

  return `/per/${pages}`
}

function sortPath(order) {
  if (order == null || order == "") return null

  return `/sort/${order}`
}

function joinCollectionPath(name, data) {
  if (empty(data)) return null

  return `/${name}/${data.join("+")}`
}

function rangePath(prefix, values) {
  if (values.length == 0) return

  return `/${prefix}/${values.join("-")}`
}

function booleanPath(name, data, trueValue = true) {
  if (data != trueValue) return null

  return `/${name}`
}

function empty(data) {
  return data == null || isEmpty(data)
}
