export function currentCompetitionPath() {
  return (window.location.pathname.match(/\/competitions\/.*?\/entries/) || [])[0]
}
export function staffPicksPath() {
  return window.location.pathname.includes("staff-picks")
}
export function featuredArtPath() {
  return window.location.pathname.includes("featured-art")
}

export function basePath() {
  if (staffPicksPath()) return "/staff-picks"
  if (featuredArtPath()) return "/featured-art"

  return currentCompetitionPath() || "/artworks"
}
