import axios from "axios"

const Blytics = {}

Blytics.push = (event_type, events_data) => {
  let env = process.env.RAILS_ENV
  if (env === "test") return
  const events = events_data.map((event) => {
    return Object.assign({ event_type }, { data: event })
  })

  // Kinesis
  axios.post(`/api/events`, { event_type, events }).catch((error) => {
    if (error.response && env !== "production") console.log(error.response.errors)
  })
}
export default Blytics

export function ImpressionListings(listingsData) {
  const listings = listingsData.map((listing) => {
    return {
      entity: "Listing",
      entity_id: listing.id,
      parent: "Artist",
      parent_id: listing.artist.id,
      user_id: window.config.userID,
      useragent: window.navigator.userAgent,
    }
  })

  Blytics.push("impression", listings)
}

export function ImpressionListingsDOM() {
  const nodes = document.querySelectorAll("[data-listing-impression-dom]")

  if (nodes.length > 0) {
    const nodes_array = [...nodes]
    const data = nodes_array.map((node) => JSON.parse(node.getAttribute("data-listing-impression-dom")))
    ImpressionListings(data)
  }
}
