import * as PathHelpers from "@/lib/Listing/PathHelpers"
import omit from "lodash/omit"
import { i18n } from "@/lib/vue/plugins/i18n"
import store from "@/store/modules/filter.module.js"

const market = i18n.t("market")

function priceRange(lowValue, highValue) {
  return `${market.currency.sign}${lowValue} - ${market.currency.sign}${highValue}`
}

function sizeValueFor(size) {
  let size_caption = market.filters.sizes[size]
  if (["XL", "S"].includes(size))
    size_caption = `${size == "XL" ? ">" : "<"} ${size_caption.split("-")[+(size == "S")]}`

  return {
    caption: size_caption + market.units.length.short,
    value: market.filters.sizes[size],
  }
}

const ListingFilters = {
  sizes: [
    {
      title: "Extra Large",
      short: "XL",
      sid: "size_extra_large",
    },
    {
      title: "Large",
      short: "L",
      sid: "size_large",
    },
    {
      title: "Medium",
      short: "M",
      sid: "size_medium",
    },
    {
      title: "Small",
      short: "S",
      sid: "size_small",
    },
  ].map((value) => ({ ...value, ...sizeValueFor(value.short) })),
  orientations: ["landscape", "portrait", "square"],
  artwork_statuses: [
    { title: "Bluethumb", label: "Bluethumb", value: "primary" },
    { title: "Podium", label: "Podium", value: "secondary" },
  ],
  categories: {
    "All Art": [],
    Paintings: [
      "oil",
      "acrylic",
      "watercolour",
      "ink",
      "gouache",
      "mixed-media",
      "airbrush",
      "spray-paint",
      "stencil",
      "resin",
    ],
    Photography: ["photograph"],
    Drawings: ["pencil", "ink", "pastel", "drawing", "charcoal"],
    Sculpture: ["sculpture", "ceramic", "bronze", "metal", "fibre", "glass", "wood", "weaving"],
    Printmaking: [
      "lithograph",
      "linocut-print",
      "screenprinting",
      "giclee",
      "etching",
      "woodcut",
      "engraving",
      "reproduction-print",
      "collagraph",
      "monotype",
    ],
    Digital: ["digital"],
    "Other media": ["other-media"],
  },
  // TODO: refactor collections, mediums, and art_styles to get the values dynamically
  collections: [
    { label: "Beach & Seascape", value: "beach-art" },
    { label: "Nature", value: "nature" },
    { label: "Outback", value: "outback" },
    { label: "Nude", value: "nude" },
    { label: "People & Portrait", value: "people-portrait" },
    { label: "Fantasy", value: "fantasy" },
    { label: "Landscape", value: "landscape" },
    { label: "Still Life", value: "still-life" },
    { label: "Aboriginal", value: "aboriginal" },
    { label: "Animal", value: "animal" },
    { label: "Flower", value: "flower" },
    { label: "Tree", value: "tree" },
    { label: "Water", value: "water" },
    { label: "Boat & Ship", value: "boat-ship" },
    { label: "Horse", value: "horse" },
    { label: "Bird", value: "bird" },
    { label: "Cat", value: "cat" },
    { label: "Dog", value: "dog" },
    { label: "Interiors", value: "interiors" },
    { label: "Garden", value: "garden" },
    { label: "Patterns", value: "patterns" },
    { label: "Cartoon", value: "cartoon" },
    { label: "Children", value: "children" },
    { label: "Men", value: "men" },
    { label: "Women", value: "women" },
    { label: "Body", value: "body" },
    { label: "Aerial", value: "aerial" },
    { label: "Space", value: "space" },
    { label: "City", value: "city" },
    { label: "Architure", value: "architecture" },
    { label: "Places", value: "places" },
    { label: "Food & Drink", value: "food-drink" },
    { label: "Music", value: "music" },
    { label: "Religious", value: "religious" },
    { label: "Sport", value: "sport" },
    { label: "Fashion", value: "fashion" },
    { label: "Culture", value: "culture" },
    { label: "Celebrity", value: "celebrity" },
    { label: "Political", value: "political" },
    { label: "Mortality", value: "mortality" },
  ],
  mediums: [
    { label: "Mixed Media", value: "mixed-media" },
    { label: "Photograph", value: "photograph" },
    { label: "Resin", value: "resin" },
    { label: "Other Media", value: "other-media" },
    { label: "Acrylic", value: "acrylic" },
    { label: "Oil", value: "oil" },
    { label: "Watercolour", value: "watercolour" },
    { label: "Drawing", value: "drawing" },
    { label: "Reproduction Print", value: "reproduction-print" },
    { label: "Ink", value: "ink" },
    { label: "Charcoal", value: "charcoal" },
    { label: "Pencil", value: "pencil" },
    { label: "Pastel", value: "pastel" },
    { label: "Spray Paint", value: "spray-paint" },
    { label: "Airbrush", value: "airbrush" },
    { label: "Sculpture", value: "sculpture" },
    { label: "Glass", value: "glass" },
    { label: "Wood", value: "wood" },
    { label: "Ceramic", value: "ceramic" },
    { label: "Digital", value: "digital" },
    { label: "Etching", value: "etching" },
    { label: "Woodcut", value: "woodcut" },
    { label: "Engraving", value: "engraving" },
    { label: "Stencil", value: "stencil" },
    { label: "Linocut Print", value: "linocut-print" },
    { label: "Lithograph", value: "lithograph" },
    { label: "Gouache", value: "gouache" },
    { label: "Screenprinting", value: "screenprinting" },
    { label: "Collagraph", value: "collagraph" },
    { label: "Monotype", value: "monotype" },
    { label: "Giclée", value: "giclee" },
    { label: "Weaving", value: "weaving" },
    { label: "Fibre", value: "fibre" },
    { label: "Brinze", value: "bronze" },
    { label: "Metal", value: "metal" },
  ],
  art_styles: [
    { label: "Pop", value: "pop" },
    { label: "Impressionism", value: "impressionism" },
    { label: "Australiana", value: "australiana" },
    { label: "Modern", value: "modern" },
    { label: "Illustration", value: "illustration" },
    { label: "Surrealism", value: "surrealism" },
    { label: "Documentary", value: "documentary" },
    { label: "Minimalism", value: "minimalism" },
    { label: "Realism", value: "realism" },
    { label: "Black & White", value: "black-white" },
    { label: "Graffiti", value: "graffiti" },
    { label: "Street Art", value: "street-art" },
    { label: "Botanical", value: "botanical" },
    { label: "Expressionism", value: "expressionism" },
    { label: "Figurative", value: "figurative" },
    { label: "Portraiture", value: "portraiture" },
    { label: "Mid Century", value: "mid-century" },
    { label: "Photorealism", value: "photorealism" },
    { label: "Abstract", value: "abstract" },
  ],
  locations: [
    { title: "VIC", value: "VIC" },
    { title: "NSW", value: "NSW" },
    { title: "ACT", value: "ACT" },
    { title: "TAS", value: "TAS" },
    { title: "QLD", value: "QLD" },
    { title: "WA", value: "WA" },
    { title: "SA", value: "SA" },
    { title: "NT", value: "NT" },
  ],
  prices: [
    { title: "All", value: "0-99999" },
    { title: `Over ${market.currency.sign}5,000`, value: "5000-99999" },
    { title: priceRange("5,000", "1,000"), value: "1000-5000" },
    { title: priceRange("1,000", "500"), value: "500-1000" },
    { title: `Under ${market.currency.sign}500`, value: "0-500" },
  ],
  sortLabelMap: () => ({
    ...(PathHelpers.currentCompetitionPath() ? { "latest-entry": "Latest Art Prize Entry" } : {}),
    ...(PathHelpers.featuredArtPath() ? { "latest-exclusive": "Most Relevant" } : {}),
    relevant: "New Art",
    ...(PathHelpers.staffPicksPath() ? { featured_at: "Most Relevant" } : {}),
    popular: "Popular",
    "price-low": "Price: Low to High",
    "price-high": "Price: High to Low",
    recently_uploaded: "Feed",
    display_order: "Display Order",
  }),
  sortKeys() {
    return Object.keys(omit(this.sortLabelMap(), ["display_order"]))
  },
  sortOptions() {
    return Object.entries(omit(this.sortLabelMap(), ["display_order"])).map((option) => ({
      title: option[1],
      value: option[0],
    }))
  },
  availabilityLabelMap: {
    all: "Include sold",
    for_sale_web: "Exclude sold",
  },
  refiningLabelMap: {
    collections: "Subject",
    art_styles: "Style",
    artwork_status: "Artwork Status",
    categories: "Categories",
    mediums: "Medium",
    sizes: "Size",
    orientations: "Orientation",
    prices: "Price",
    colors: "Colour",
    locations: "Artist Location",
    displays: "Display",
  },
}

export const defaultSortOption = () => {
  if (PathHelpers.currentCompetitionPath()) return "popular"
  if (PathHelpers.staffPicksPath()) return "featured_at"
  if (PathHelpers.featuredArtPath()) return store.state.userArtist ? "recently_uploaded" : "latest-exclusive"

  return "relevant"
}

export const sizeToMetaId = (val) => {
  return ListingFilters.sizes.find((size) => size.value == val).sid
}

export default ListingFilters
export const categories = ListingFilters.categories
